@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: hotpink;
}

body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

canvas {
  display: block;
}

#video
{
  position: fixed;
  z-index: -2;
  width: 100%;
  object-fit: fill;
  top: 0;
  left: 0;
}

/* Ensure the html, body, and root elements take up the full height */
html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

canvas {
  display: block;
  position: fixed;
  z-index: -2;
}

.masonry { /* Masonry container */
  column-count: 4;
  column-gap: 1em;
  z-index: 1;
  padding: 80px 60px 60px 80px;
}

.item { /* Masonry bricks or child elements */
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
}

.item img
{
  width: 100%;
  height: 100%;
}

/* Box-sizing reset: //w3bits.com/?p=3225 */

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
  .masonry {
    column-count: 4;
  }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .masonry {
    column-count: 3;
  }
  .crsl
  {
    width: 300px;
  }
}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
  .masonry {
    column-count: 2;
  }
}

@media only screen and (max-width: 539px) {
  .masonry {
    column-count: 1;
  }
}

.image-wrapper {
  position: relative;
  overflow: hidden;
}

.image-wrapper img {
  width: 100%;
  transition: transform 0.3s ease;
}

.description-item {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  color: white; /* White text */
  padding: 20px; /* Add some padding for better readability */
  border-radius: 5px; /* Optional: Add some border radius for rounded corners */
}

.image-wrapper .description {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-wrapper:hover img {
  transform: scale(1.1);
}

.image-wrapper:hover .description {
  opacity: 1;
}

.ide-text {
  margin-top: 225px;
  margin-right: 50px;
  font-size: larger;
  border-radius: 8px;
  text-wrap: wrap;
  width: 50%;
  height: 193px;
  background-color: #1e1e1e; /* Dark background similar to IDE */
  color: #7aef7a; /* Light text color */
  font-family: 'Source Code Pro', monospace; /* Monospaced font */
  padding: 20px;
  overflow-y: auto; /* Scrollable if content exceeds height */
  box-sizing: border-box; 
}

/* Large devices (desktops, 1200px and up) */
@media (min-width: 1200px) {
  .ide-text {
    margin-top: 225px;
    width: 50%;
    font-size: larger;
  }
}

/* Medium devices (tablets, 992px to 1199px) */
@media (max-width: 1199px) and (min-width: 992px) {
  .ide-text {
    margin-top: 200px;
    margin-right: 40px;
    width: 60%;
    font-size: large;
  }
}

/* Small devices (landscape phones, 768px to 991px) */
@media (max-width: 991px) and (min-width: 768px) {
  .ide-text {
    margin-top: 150px;
    margin-right: 30px;
    width: 70%;
    font-size: medium;
  }
}

/* Extra small devices (portrait phones, less than 768px) */
@media (max-width: 767px) {
  .ide-text {
    margin-top: 100px;
    margin-right: 20px;
    width: 80%;
    font-size: small;
  }
}

/* Very small devices (less than 576px) */
@media (max-width: 575px) {
  .ide-text {
    margin-top: 80px;
    margin-right: 10px;
    width: 90%;
    font-size: smaller;
  }
}

.ide-text p {
  margin: 0;
  line-height: 1.5;
  white-space: pre; 
  text-wrap: wrap;/* Preserve whitespace for indentation */
}

.home-container {
  display: flex;
  height: 100vh; /* Full viewport height */
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #21008289; /* Dark indigo color */
  z-index: -3; /* Behind other content but above video */
}

.portfolio
{
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio img
{
  width: 200px;
  border-radius: 15px;
}

.carousel-text
{
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  color: white; /* White text */
  padding: 20px; /* Add some padding for better readability */
  border-radius: 5px; /* Optional: Add some border radius for rounded corners */
  align-items: center;
}

.crsl
{
  width: 100%;
  max-width: 500px;
}

.portfolio-top-text
{
  padding-top: 80px;
  color: white;
  font-size: xx-large;
  font-weight: bold;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-top-text {
  padding-top: 60px;
  color: white;
  font-size: 1.5rem; /* Default font size */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* Center align text for smaller screens */
  flex-wrap: wrap; /* Ensure content wraps on small screens */
}

/* Larger devices */
@media (min-width: 1200px) {
  .portfolio-top-text {
    padding-top: 100px; /* Increase padding for larger screens */
    font-size: 2rem; /* Larger font size for larger screens */
  }
}

/* Medium devices */
@media (max-width: 1199px) and (min-width: 992px) {
  .portfolio-top-text {
    padding-top: 80px; /* Adjusted padding for medium screens */
    font-size: 1.75rem; /* Slightly larger font size for medium screens */
  }
}

/* Smaller devices */
@media (max-width: 991px) and (min-width: 768px) {
  .portfolio-top-text {
    padding-top: 70px; /* Reduced padding for smaller screens */
    font-size: 1.5rem; /* Default font size for smaller screens */
  }
}

/* Extra small devices */
@media (max-width: 767px) {
  .portfolio-top-text {
    padding-top: 70px; /* Further reduced padding for extra small screens */
    font-size: 1.25rem; /* Smaller font size for extra small screens */
  }
}

/* Extra extra small devices */
@media (max-width: 575px) {
  .portfolio-top-text {
    padding-top: 70px; /* Minimum padding for very small screens */
    font-size: 1rem; /* Smallest font size for very small screens */
  }
}


.footer
{
  color: white;
  text-align: right;
  padding-right: 20px;
  padding-bottom: 20px;
}

/* Navbar.css */
.nav-menu {
  display: flex;
  align-items: center;
  margin-right: -24px;
}

.nav-menu.active {
  display: flex;
}

.nav-menu {
  display: none; /* Hide menu by default on mobile */
  position: absolute;
  top: 60px; /* Adjust based on your navbar height */
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9; /* Make sure it is above other content */
}

.nav-menu a {
  display: block;
  padding: 10px;
  text-align: center;
  color: #000;
  text-decoration: none;
  transition: background-color 0.3s;
}

.nav-menu a:hover {
  background-color: #f0f0f0;
}

.nav-menu .nav-link {
  color: #000;
  text-decoration: none;
}

.nav-menu .nav-link.active {
  color: #000;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .nav-menu {
    display: flex;
    position: static;
    flex-direction: row;
    background: transparent;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  .nav-menu.active {
    display: none; /* Hide active state on larger screens */
  }
}


.expicons img
{
  width: 25px;
  margin: 10px;
}